<template>
	<div class="content" :style="{ background: isBackground ? '#ffffff' : '' }">
		<div class="main nav">
			<img
				v-show="isBackground"
				class="logo"
				@click="goHome"
				src="../../assets/pc/pcHeader/logo_black.png"
				alt=""
			/>
			<img
				v-show="!isBackground"
				class="logo"
				@click="goHome"
				src="../../assets/pc/pcHeader/logo_white.png"
				alt=""
			/>
			<div
				class="navList"
				:style="{ color: isBackground ? '#000000' : '#ffffff' }"
			>
				<div
					class="item"
					@click="select(item)"
					v-for="item in navList"
					:key="item.id"
				>
					<div
						:class="
							item.id == navIndex && isBackground
								? 'theme_font'
								: ''
						"
						:style="{
							'font-weight': item.id == navIndex ? 'bold' : ''
						}"
					>
						{{ item.title }}
					</div>
					<div
						v-show="!isBackground"
						:class="item.id == navIndex ? 'index' : ''"
					></div>
					<div
						v-show="isBackground"
						:class="item.id == navIndex ? 'index theme_bg' : ''"
					></div>
				</div>
				<div class="download_out">
					<div class="download theme_bg">下载APP</div>
					<div class="download_content_out">
						<div class="download_inner">
							<div class="download_left">
								<vue-scroll
									:ops="ops"
									style="width: 100%; height: 100%;"
								>
									<div class="download_tab_list">
										<div
											:class="
												item.id == appIndex
													? 'download_tab active theme_bg'
													: 'download_tab'
											"
											@click="selectApp(item)"
											v-for="item in schoolAppList"
											:key="item.id"
										>
											{{
												item.appName == '聚趣网校' ||
												item.appName == '德语学习背单词'
													? item.appName
													: item.appName + 'GO'
											}}
										</div>
									</div>
								</vue-scroll>
							</div>
							<div
								class="download_right"
								v-show="appIndex == item.id"
								v-for="(item, i) in schoolAppList"
								:key="i"
							>
								<div class="download_title">
									<div
										class="download_logo"
										@click="toAppWebsite(item.languageid)"
									>
										<img
											class="logo_img"
											:src="item.icon"
											alt=""
										/>
										{{
											item.appName == '聚趣网校' ||
											item.appName == '德语学习背单词'
												? item.appName
												: item.appName + 'GO'
										}}
									</div>
									<div class="download_edition">
										{{ item.appVersion }}
									</div>
								</div>
								<div class="download_introduce">
									{{ item.appInfo }}
								</div>
								<div class="download_content">
									<img
										class="app_edition_img"
										:src="item.appImg1"
										alt=""
									/>
									<img
										class="app_edition_img"
										:src="item.appImg2"
										alt=""
									/>
									<div class="download_edition_img_out">
										<img
											class="download_edition_img"
											:src="item.appQrimg2"
											alt=""
										/>
										<div class="download_edition_title">
											ios下载
										</div>
										<img
											class="download_edition_img"
											:src="item.appQrimg1"
											alt=""
										/>
										<div class="download_edition_title">
											安卓下载
										</div>
									</div>
								</div>
								<div class="download_des">
									开发者信息：长沙聚趣教育科技有限公司
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
const axios = require('axios')
import { navList } from '../../utils/navList.js'
import { navList2 } from '../../utils/navList2.js'
export default {
	data() {
		return {
			isBackground: false,
			item: {},
			navIndex: 1,
			navList: [],
			appIndex: 1,
			schoolAppList: [],
			ops: {
				vuescroll: {},
				scrollPanel: {},
				rail: {
					keepShow: true
				},
				bar: {
					hoverStyle: true,
					onlyShowBarOnScroll: false, //是否只有滚动的时候才显示滚动条
					background: '#707070', //滚动条颜色
					opacity: 0.2, //滚动条透明度
					'overflow-x': 'hidden'
				}
			}
		}
	},
	watch: {
		$route: {
			handler() {
				this.getNavItem('watch')
			},
			// 深度观察监听
			deep: true
		}
	},
	created() {
		this.getNavItem()
		this.getSchoolAppList()
		// this.selectSwitch()
	},
	mounted() {
		window.addEventListener('scroll', this.windowScroll) //监听页面滚动
	},
	methods: {
		// 监听页面滚动 获取滚动条位置
		windowScroll() {
			let scrollTop =
				window.pageYOffset ||
				document.documentElement.scrollTop ||
				document.body.scrollTop
			if (scrollTop > 10) {
				this.isBackground = true
			} else {
				this.isBackground = false
			}
		},
		getNavItem(type) {
			if (sessionStorage.item) {
				var item = sessionStorage.item
				item = JSON.parse(item)
				console.log(item)
				this.navIndex = item.id
				if (item.name == 'courseDetails') {
					this.navList = navList.filter(function(item) {
						return item.title == '课程'
					})
					this.isBackground = true
				} else {
					this.navList = navList.filter(function(item) {
						return item.title != '课程'
					})
				}
				if (type == 'watch') {
					return
				}
				if (item.name != 'home') {
					if (sessionStorage.paramsObj) {
						this.$router.push({
							name: item.name,
							query: JSON.parse(sessionStorage.paramsObj)
						})
					} else {
						this.$router.push({ name: item.name })
					}
				}
			}
		},
		// 查询网页开关
		async selectSwitch() {
			axios
				.post('https://api.juquedu.com/admin/apply/selectSwitch', {})
				.then((result) => {
					//state  0 是关闭 1是打开
					if (result.data.state == 1) {
						this.navList = navList
					} else {
						this.navList = navList2
					}
				})
		},
		// 获取app配置
		async getSchoolAppList() {
			var params = new URLSearchParams()
			params.append('page', 0)
			params.append('size', 99999)
			params.append('sort', 'sort,asc')
			const { data: res } = await this.$http.post(
				'school/schoolApp/un/getlist',
				params
			)
			if (res.status == 1001) {
				res.data.content.forEach((item) => {
					var languageid = item.languageid
					if (languageid == 4) {
						sessionStorage.setItem(
							'germanApp',
							JSON.stringify(item)
						)
						item.appNameE = 'GERMAN'
					}
					if (languageid == 5) {
						sessionStorage.setItem(
							'frenchApp',
							JSON.stringify(item)
						)
						item.appNameE = 'FRENCH'
					}
					if (languageid == 3) {
						sessionStorage.setItem(
							'japaneseApp',
							JSON.stringify(item)
						)
						item.appNameE = 'JAPANESE'
					}
					if (languageid == 1) {
						sessionStorage.setItem(
							'koreanApp',
							JSON.stringify(item)
						)
						item.appNameE = 'KOREAN'
					}
					if (languageid == 6) {
						sessionStorage.setItem(
							'juquSchoolApp',
							JSON.stringify(item)
						)
						item.appNameE = 'IELTS'
					}
					if (languageid == 2) {
						sessionStorage.setItem(
							'spanishApp',
							JSON.stringify(item)
						)
						item.appNameE = 'SPANISH'
					}
				})
				sessionStorage.setItem(
					'schoolApp',
					JSON.stringify(res.data.content)
				)
				this.schoolAppList = JSON.parse(
					sessionStorage.getItem('schoolApp')
				)
				this.appIndex = this.schoolAppList[0].id
			}
		},
		goHome() {
			this.navIndex = 1
			this.$router.push({ name: 'home' })
		},
		select(item) {
			if (item.name == 'product') {
				window.open('https://ke.juquedu.com/index.html')
				this.goHome()
			} else {
				this.navIndex = item.id
				sessionStorage.item = JSON.stringify(item)
				this.$router.push({ name: item.name })
			}
		},
		selectApp(item) {
			this.appIndex = item.id
		},
		toAppWebsite(languageid) {
			switch (languageid) {
				case 1:
					window.open('https://korean.juquedu.com/website/index.html')
					break
				case 2:
					window.open('https://spain.juquedu.com/website/index.html')
					break
				case 3:
					window.open('https://api.juquedu.com/website/index.html')
					break
				case 4:
					window.open('https://german.juquedu.com/website/index.html')
					break
				case 5:
					window.open('https://fr.juquedu.com/website/index.html')
					break
				case 6:
					window.open('https://ielts.juquedu.com/download/index.html')
					break
				case 100:
					window.open('https://ke.juquedu.com/website/index.html')
					break
				case 101:
					window.open('https://germanword.juquedu.com/#/index')
					break
			}
		}
	}
}
</script>

<style lang="scss" scoped>
.content {
	position: fixed;
	top: 0;
	z-index: 99;
	width: 100%;
}
.nav {
	display: flex;
	align-items: center;
	height: 64px;
	justify-content: space-between;
	.logo {
		width: 160px;
		height: 32px;
	}
	.logo:hover {
		cursor: pointer;
	}
	.navList {
		display: flex;
		align-items: center;
		justify-content: space-between;
		font-size: 18px;
		height: 100%;
		color: #ffffff;
		.item {
			cursor: pointer;
			height: 100%;
			line-height: 64px;
			margin-right: 76px;
			font-size: 18px;
			position: relative;
			.index {
				width: 18px;
				margin: auto;
				content: '';
				position: relative;
				bottom: 10px;
				display: block;
				height: 3px;
				text-align: center;
				background-color: #ffffff;
				border-radius: 3px;
				animation: fadeInLeft 0.3s ease 0.2s 1 both;
			}
		}
		.item:last-child {
			margin-right: 89px;
		}
		.download_out {
			position: relative;
			.download {
				width: 120px;
				height: 42px;
				line-height: 42px;
				text-align: center;
				color: #ffffff;
				font-size: 18px;
				border-radius: 21px;
				cursor: pointer;
			}
			.download_content_out {
				position: absolute;
				right: 0;
				display: none;
				.download_inner {
					width: 584px;
					background: #ffffff;
					border-radius: 12px;
					margin-top: 24px;
					display: flex;
					justify-content: space-between;
					box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.12);
					border-radius: 12px;
					.download_left {
						width: 150px;
						padding: 22px 0;
						box-sizing: border-box;
						.download_tab_list {
							height: 399px;
							padding: 0 16px;
							box-sizing: border-box;
							.download_tab {
								width: 118px;
								height: 48px;
								line-height: 48px;
								text-align: center;
								background: #f8f2ef;
								border-radius: 8px;
								margin-top: 12px;
								font-size: 16px;
								color: #333333;
								cursor: pointer;
							}
							.download_tab:first-child {
								margin-top: 0;
							}
							.active {
								color: #ffffff;
							}
						}
					}
					.download_right {
						width: 434px;
						height: 438px;
						background: #f8f2ef;
						opacity: 1;
						border-radius: 0px 12px 12px 0px;
						padding: 16px 20px 18px;
						box-sizing: border-box;
						.download_title {
							display: flex;
							align-items: center;
							justify-content: space-between;
							color: #333333;
							font-size: 16px;
							.download_logo {
								display: flex;
								align-items: center;
								justify-content: center;
								cursor: pointer;
								.logo_img {
									width: 40px;
									height: 40px;
									margin-right: 10px;
									border-radius: 8px;
								}
							}
							.download_edition {
								color: #5f6464;
								font-size: 14px;
							}
						}
						.download_introduce {
							height: 56px;
							padding-right: 10px;
							box-sizing: border-box;
							margin: 12px 0 24px;
							color: #333333;
							font-size: 14px;
						}
						.download_content {
							display: flex;
							align-items: center;
							overflow: hidden;
							.app_edition_img {
								width: 136px;
								height: 242px;
								margin-right: 24px;
								border-radius: 8px;
							}
							.app_edition_img:first-child {
								margin-right: 10px;
							}
							.download_edition_img_out {
								height: 242px;
								.download_edition_img {
									width: 88px;
									height: 88px;
								}
								.download_edition_title {
									margin-top: 6px;
									margin-bottom: 16px;
									color: #333333;
									font-size: 12px;
									text-align: center;
								}
							}
						}
						.download_des {
							margin: 16px auto 0;
							color: #333333;
							font-size: 14px;
							text-align: center;
						}
					}
				}
			}
		}
		.download_out:hover {
			.download_content_out {
				display: block;
			}
		}
	}
}
</style>
